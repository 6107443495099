<template>
  <page-content>
    <page-title heading="โครงสร้างภาค">
      <template v-slot:right>
        <b-button variant="danger" @click="onCreate">เพิ่มภาค</b-button>
      </template>
    </page-title>

    <b-row align-v="center" class="mt-4 mt-sm-0">
      <b-col sm="8" md="6" class="pr-0">
        <b-form-group class="mb-0 pr-2">
          <b-input-group class="input-group-seamless">
            <b-form-input
              placeholder="ค้นหารายชื่อภาค"
              v-model="keyword"
              @keyup.enter="onKeywordSearched"
            ></b-form-input>

            <b-input-group-text slot="prepend">
              <font-awesome-icon class="mx-auto" icon="search" />
            </b-input-group-text>

            <b-input-group-text v-show="keyword.length" slot="append">
              <a href="javascript:void(0)" @click="clearKeyword">
                <font-awesome-icon class="mx-auto" icon="window-close" />
              </a>
            </b-input-group-text>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col sm="4" md="2" class="pr-2 pl-3 pl-sm-2 mt-4 mt-sm-0">
        <b-button variant="primary" block size="sm" @click="onKeywordSearched"
          >ค้นหา</b-button
        >
      </b-col>
    </b-row>

    <headquarter-table
      ref="table"
      class="mt-4"
      :fields="fields"
      :params="headquarterTableParams"
      @edit="onEdit"
      @delete="onDelete"
    />

    <create-or-update-headquarter
      :edit-data.sync="editData"
      v-model="showModalHeadquarter"
      @create:success="onCreateSuccess"
    ></create-or-update-headquarter>

    <delete-headquarter
      v-model="showModalDelete"
      @delete:success="onDeleteSuccess"
      :delete-data.sync="deleteData"
    ></delete-headquarter>
  </page-content>
</template>

<script>
import PageContent from "../../components/layout/PageContent";
import PageTitle from "../../components/layout/PageTitle";
import CreateOrUpdateHeadquarter from "../../components/modal/CreateOrUpdateHeadquarter";
import HeadquarterTable from "../../components/table/Headquarter";
import DeleteHeadquarter from "../../components/modal/DeleteHeadquarter";
import { Auth } from "../../models";

export default {
  components: {
    PageContent,
    PageTitle,
    CreateOrUpdateHeadquarter,
    HeadquarterTable,
    DeleteHeadquarter,
  },

  data() {
    return {
      keyword: "",
      formfilter: {},
      editData: {},
      deleteData: {},
      showModalHeadquarter: false,
      showModalDelete: false,
      fields: [
        { key: "m_headquarters_name", label: "ภาค", sortable: true },
        {
          key: "createdAt",
          label: "วันที่สร้าง",
          sortable: true,
        },
        {
          key: "updatedAt",
          label: "แก้ไขล่าสุด",
          sortable: true,
        },
      ],
    };
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    headquarterTableParams() {
      const { formfilter } = this;

      return {
        ...formfilter,
      };
    },
  },

  methods: {
    onKeywordSearched() {
      this.$set(this.formfilter, "keyword", this.keyword);
    },

    clearKeyword() {
      this.keyword = "";
      this.$delete(this.formfilter, "keyword");
    },

    onCreate() {
      this.showModalHeadquarter = true;
    },

    async onEdit(item) {
      this.editData = { ...item };

      this.showModalHeadquarter = true;
    },

    onCreateSuccess() {
      this.$refs.table.refresh();
    },

    onDelete(item) {
      this.deleteData = { ...item };
      this.showModalDelete = true;
    },

    onDeleteSuccess() {
      this.$refs.table.refresh();
    },
  },
};
</script>
