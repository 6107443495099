<template>
  <b-form @submit.stop.prevent>
    <div class="d-flex flex-row flex-wrap">
      <div class="col-12 px-2 mb-2">
        <label class="font-weight-bold" for="headquarterName">ภาค</label>

        <b-form-input
          id="headquarterName"
          :state="invalid ? validation.form.m_headquarters_name.required : null"
          type="text"
          :disabled="disabled"
          v-model="form.m_headquarters_name"
        ></b-form-input>

        <b-form-invalid-feedback id="headquarterName-feedback">
          กรุณากรอกภาค
        </b-form-invalid-feedback>
      </div>
    </div>
  </b-form>
</template>

<script>
export default {
  props: {
    formData: Object,
    editData: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Object,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabledInput: {
      type: Object,
      default: () => ({}),
    },
  },

  model: {
    prop: "formData",
    event: "change",
  },

  data() {
    return {
      form: {
        m_headquarters_name: this.editData.m_headquarters_name || "",
      },
    };
  },

  watch: {
    form: {
      deep: true,
      immediate: true,
      handler: "syncData",
    },
  },

  methods: {
    syncData(v) {
      this.$emit("change", v);
    },
  },
};
</script>
